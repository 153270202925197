import * as THREE from "three"

import React, { useEffect, useRef } from 'react'

import FOG from "vanta/dist/vanta.fog.min"

export default function Layout({ children, transitionStatus, entry, exit }) {

    const backgroundRef = useRef();

    useEffect(() => {
        FOG({
            el: backgroundRef.current,
            THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            highlightColor: 0x360fff,
            midtoneColor: 0xffa400,
            lowlightColor: 0xff0000,
            baseColor: 0x0,
            blurFactor: 0.80,
            speed: 1.50,
            zoom: 1.00
        })
    });

    return (
        <main ref={backgroundRef} className={`relative h-screen ${transitionStatus}`}>
            {children}
            <div className='absolute inset-x-0 bottom-6'>
                <span className='block text-sm text-center w-full font-light text-slate-100'>copyright © 2022 ritthikrai.w</span>
            </div>
        </main>
    )
}