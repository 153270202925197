import React, { useEffect, useRef } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../layouts/index'
import { gsap } from 'gsap'
import siteData from '../data/site-data.json'

const IndexPage = () => {
  const titleRef = useRef()
  const subTitleRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline()

    tl.from(titleRef.current, {
      duration: 0.65,
      delay: 0.85,
      y: 100,
      opacity: 0,
      ease: 'power1.out',
    })

    tl.from(
      subTitleRef.current,
      {
        duration: 0.65,
        delay: 0.25,
        y: 100,
        opacity: 0,
        ease: 'power1.out',
      },
      '-=0.5',
    )
  })

  return (
    <Layout>
      <Helmet
        title={siteData.title}
        meta={[
          {
            name: `description`,
            content: siteData.subTitle,
          },
          {
            property: `og:title`,
            content: siteData.title,
          },
          {
            property: `og:description`,
            content: siteData.subTitle,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: siteData.title,
          },
          {
            name: `twitter:description`,
            content: siteData.subTitle,
          },
        ]}
      />
      <section className="relative h-screen">
        <div className="flex items-center justify-center h-full">
          <div className="w-full text-center p-5">
            <div className="relative overflow-hidden">
              <h1 ref={titleRef} className="text-5xl md:text-6xl leading-none tracking-widest font-medium text-white mb-3">
                {siteData.title}
              </h1>
            </div>
            <div className="relative overflow-hidden">
              <p ref={subTitleRef} className="text-3xl md:text-4xl font-thin text-white">
                {siteData.subTitle}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="absolute inset-x-0 bottom-28">
        <ul className="font-light text-slate-100 list-none flex flex-wrap justify-center gap-4">
          <li>
            <span>
              <a href={siteData.facebook} target="_blank" rel="noreferrer noopener">
                Facebook
              </a>
            </span>
          </li>
          <li>
            <span>
              <a href={siteData.instrgram} target="_blank" rel="noreferrer noopener">
                Instagram
              </a>
            </span>
          </li>
          <li>
            <span>
              <a href={siteData.linkedin} target="_blank" rel="noreferrer noopener">
                Linkedin
              </a>
            </span>
          </li>
          <li className="w-full text-center">
            <span>
              <a href={`mailto:${siteData.email}`}>{siteData.email}</a>
            </span>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage
